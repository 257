<template>
  <div class="fullScreen" :class="{ show: true }" v-loading="loading">
    <Crumbs> </Crumbs>
    <div class="fullScreenMain">
      <div class="fullScreenMainCon">
        <el-tabs :before-leave="isShow ? beforeFn : leaveFn" v-model="activeName">
          <template v-for="item in deptList">
            <el-tab-pane :label="item.deptName" :name="`${item.id}`" :key="item.id"></el-tab-pane>
          </template>
        </el-tabs>
        <el-card class="box-card" style="margin-bottom: 10px">
          <div slot="header" class="clearfix">
            <span style="color: #409eff; font-size: 18px; font-weight: 900">岗位</span>
          </div>

          <el-descriptions :column="1" border>
            <el-descriptions-item label-class-name="labelClassName">
              <template v-for="(item, index) in businessDeptLimitRuleList">
                <template v-if="item.roleType === 'VICEGENERAL_MANAGER_ROLE'">
                  <span style="margin: 0 10px 0 0" class="memberClass" :key="index">
                    <span class="postName">{{ item.roleType | dict(businessAccuntingList) }}</span>
                    :
                    <el-input
                      @focus="postFocusFn(item.roleType)"
                      @blur="postBlurFn(item.roleType)"
                      v-model.number="item.amount"
                      :class="item.sign == 1 ? 'color bd_input' : ' bd_input'"
                    ></el-input
                  ></span>
                </template>
                <template v-if="item.roleType === 'DEPT_MANAGER_ROLE'">
                  <span style="margin: 0 10px 0 0" class="memberClass" :key="index">
                    <span class="postName">{{ item.roleType | dict(businessAccuntingList) }}</span>
                    :
                    <el-input
                      @focus="postFocusFn(item.roleType)"
                      @blur="postBlurFn(item.roleType)"
                      v-model.number="item.amount"
                      :class="item.sign == 1 ? 'color bd_input' : ' bd_input'"
                    ></el-input
                  ></span>
                </template>
                <template v-if="item.roleType === 'MEMBER_ROLE'">
                  <span style="margin: 0 10px 0 0" class="memberClass" :key="index">
                    <span class="postName">{{ item.roleType | dict(businessAccuntingList) }}</span>
                    :
                    <el-input
                      @focus="postFocusFn(item.roleType)"
                      @blur="postBlurFn(item.roleType)"
                      v-model.number="item.amount"
                      :class="item.sign == 1 ? 'color bd_input' : ' bd_input'"
                    ></el-input
                  ></span>
                </template>
              </template>
              <el-button type="success" @click="setUpFn">一键设置</el-button>
            </el-descriptions-item></el-descriptions
          >
        </el-card>

        <el-card class="box-card">
          <el-collapse v-model="activeNames">
            <el-collapse-item name="1">
              <template slot="title">
                <div v-if="vicegeneralList.length > 0">
                  {{ vicegeneralList[0].roleType | dict(businessAccuntingList) }}
                </div>
              </template>
              <el-descriptions :column="1" border>
                <el-descriptions-item label-class-name="labelClassName">
                  <template v-for="item in vicegeneralList">
                    <div class="memberClass" :key="item.staffId">
                      <span>
                        <span class="ClassName">{{ item.staffName }}</span
                        >:
                        <el-input
                          @focus="focusFn(item.staffId, 'VICEGENERAL_MANAGER_ROLE')"
                          @blur="blurFn(item.staffId, 'VICEGENERAL_MANAGER_ROLE')"
                          v-model.number="item.amount"
                          :class="[
                            item.sign == 1 ? 'color bd_input' : ' bd_input',
                            item.typefaceColor == 1 ? 'typefaceColor' : '',
                          ]"
                        ></el-input>
                      </span>
                    </div>
                  </template>
                </el-descriptions-item>
              </el-descriptions>
            </el-collapse-item>
            <el-collapse-item title="部门经理" name="2">
              <template slot="title">
                <div v-if="managerList.length > 0">
                  {{ managerList[0].roleType | dict(businessAccuntingList) }}
                </div>
              </template>
              <el-descriptions :column="1" border>
                <el-descriptions-item label-class-name="labelClassName">
                  <template v-for="item in managerList">
                    <div class="memberClass" :key="item.staffId">
                      <span :class="item.typefaceColor == 1 ? 'typefaceColor' : ''">
                        <span class="ClassName">{{ item.staffName }}</span
                        >:
                        <el-input
                          @focus="focusFn(item.staffId, 'DEPT_MANAGER_ROLE')"
                          @blur="blurFn(item.staffId, 'DEPT_MANAGER_ROLE')"
                          v-model.number="item.amount"
                          :class="[
                            item.sign == 1 ? 'color bd_input' : ' bd_input',
                            item.typefaceColor == 1 ? 'typefaceColor' : '',
                          ]"
                        ></el-input>
                      </span>
                    </div>
                  </template>
                </el-descriptions-item>
              </el-descriptions>
            </el-collapse-item>
            <el-collapse-item title="部门成员" name="3">
              <template slot="title">
                <div v-if="memberList.length > 0">
                  {{ memberList[0].roleType | dict(businessAccuntingList) }}
                </div>
              </template>
              <el-descriptions :column="1" border>
                <el-descriptions-item label-class-name="labelClassName">
                  <template v-for="item in memberList">
                    <div class="memberClass" :key="item.staffId">
                      <span>
                        <span class="ClassName">{{ item.staffName }}</span
                        >:
                        <el-input
                          @focus="focusFn(item.staffId, 'MEMBER_ROLE')"
                          @blur="blurFn(item.staffId, 'MEMBER_ROLE')"
                          v-model.number="item.amount"
                          :class="[
                            item.sign == 1 ? 'color bd_input' : ' bd_input',
                            item.typefaceColor == 1 ? 'typefaceColor' : '',
                          ]"
                        ></el-input>
                      </span>
                    </div>
                  </template>
                </el-descriptions-item>
              </el-descriptions>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </div>
      <div class="fullScreenOperation">
        <el-button type="primary" @click="submit" v-loading="loading">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      loading: false,
      activeName: '6965573941734416384', //6965573941734416384
      activeNames: ['1', '2', '3'],
      deptList: [],
      businessDeptLimitRuleList: [],
      businessStaffLimitRuleList: [],
      oldbusinessDeptLimitRuleList: [],
      vicegeneralList: [],
      managerList: [],
      memberList: [],
      businessStaffDeleteList: [],
      businessAccuntingList: [],
      isShow: false,
    }
  },
  computed: {},
  watch: {
    vicegeneralList: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        for (let index = 0; index < val.length; index++) {
          const v = val[index]
          if (v.sign === 1) {
            this.isShow = true
            break
          }
        }
        for (let index = 0; index < this.oldbusinessDeptLimitRuleList.length; index++) {
          const n = this.oldbusinessDeptLimitRuleList[index]
          for (let index = 0; index < val.length; index++) {
            const v = val[index]
            if (v.roleType === n.roleType) {
              if (v.amount != n.amount) {
                v.typefaceColor = 1
              } else {
                v.typefaceColor = 0
              }
            }
          }
        }
      },
    },
    managerList: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        for (let index = 0; index < val.length; index++) {
          const v = val[index]
          if (v.sign === 1) {
            this.isShow = true
            break
          }
        }
        for (let index = 0; index < this.oldbusinessDeptLimitRuleList.length; index++) {
          const n = this.oldbusinessDeptLimitRuleList[index]
          for (let index = 0; index < val.length; index++) {
            const v = val[index]
            if (v.roleType === n.roleType) {
              if (v.amount != n.amount) {
                v.typefaceColor = 1
              } else {
                v.typefaceColor = 0
              }
            }
          }
        }
      },
    },
    memberList: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        for (let index = 0; index < val.length; index++) {
          const v = val[index]
          if (v.sign === 1) {
            this.isShow = true
            break
          }
        }
        for (let index = 0; index < this.oldbusinessDeptLimitRuleList.length; index++) {
          const n = this.oldbusinessDeptLimitRuleList[index]
          for (let index = 0; index < val.length; index++) {
            const v = val[index]
            if (v.roleType === n.roleType) {
              if (v.amount != n.amount) {
                v.typefaceColor = 1
              } else {
                v.typefaceColor = 0
              }
            }
          }
        }
      },
    },
    businessDeptLimitRuleList: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        for (let index = 0; index < val.length; index++) {
          const v = val[index]
          if (v.sign === 1) {
            this.isShow = true
            break
          }
        }
      },
    },
    activeName: {
      handler: function (val) {
        if (val) {
          this.getData()
        }
      },
    },
  },
  created() {
    this.getData()
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    this.$api.dict
      .listSysDictData('BUSINESS_ACCUNTING', true)
      .then(res => {
        this.businessAccuntingList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    leaveFn() {},
    async beforeFn(val, oldVal) {
      await this.$confirm(
        '此页面已进行修改，如果没有保存，那么之前操作将会失效，是否跳转?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          return true
        })
        .catch(() => {
          return reject()
        })
    },
    submit() {
      let obj = {
        businessStaffDeleteList: [],
        businessStaffLimitRuleList: [],
        businessDeptLimitRuleList: [],
      }
      const arr = [this.vicegeneralList, this.managerList, this.memberList]
      arr.forEach(list => {
        list.forEach(v => {
          v.amount = String(v.amount).replace(/,/g, '')
          if (v.sign === 1) {
            obj.businessStaffLimitRuleList.push(v)
          }
        })
      })
      this.businessStaffLimitRuleList.forEach(n => {
        arr.forEach(list => {
          list.forEach(v => {
            if (v.id && n.id && v.id === n.id) {
              let amount = String(v.amount).replace(/,/g, '')
              let num = String(n.amount).replace(/,/g, '')
              if (amount != num) {
                obj.businessStaffDeleteList.push(v.id)
              } else if (v.sign === 1) {
                obj.businessStaffDeleteList.push(v.id)
              }
            }
          })
        })
      })
      obj.businessStaffLimitRuleList.forEach(v => {
        if (obj.businessStaffDeleteList.indexOf(v.id) != -1) {
          delete v.id
        }
      })

      this.businessDeptLimitRuleList.forEach(v => {
        v.amount = String(v.amount).replace(/,/g, '')
        if (v.sign === 1) {
          obj.businessDeptLimitRuleList.push(v)
        } else if (!v.id && v.amount == 0) {
          obj.businessDeptLimitRuleList.push(v)
        }
      })

      this.loading = true
      this.$api.accunting
        .edit(obj)
        .then(res => {
          this.$message.success('修改成功')
          this.getData()
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    setUpFn() {
      this.businessDeptLimitRuleList.forEach(n => {
        this.vicegeneralList.forEach(v => {
          if (n.roleType === v.roleType) {
            v.amount = n.amount
          }
        })
        this.managerList.forEach(v => {
          if (n.roleType === v.roleType) {
            v.amount = n.amount
          }
        })
        this.memberList.forEach(v => {
          if (n.roleType === v.roleType) {
            v.amount = n.amount
          }
        })
      })
    },
    postFocusFn(type) {
      this.businessDeptLimitRuleList.forEach(v => {
        if (v.roleType === type) {
          v.amount = String(v.amount).replace(/,/g, '')
        }
      })
    },
    postBlurFn(type) {
      let num = 0
      this.oldbusinessDeptLimitRuleList.forEach(v => {
        if (v.roleType == type) {
          num = String(v.amount).replace(/,/g, '')
        }
      })
      this.businessDeptLimitRuleList.forEach(v => {
        if (v.roleType === type) {
          let amount = String(v.amount).replace(/,/g, '')
          if (amount != num) {
            v.sign = 1
          } else {
            v.sign = 0
          }
          v.amount = Number(v.amount).toLocaleString()
        }
      })
    },
    focusFn(staffId, key) {
      if (key === 'VICEGENERAL_MANAGER_ROLE') {
        this.vicegeneralList.forEach(v => {
          if (v.staffId == staffId) {
            v.amount = String(v.amount).replace(/,/g, '')
          }
        })
      } else if (key === 'DEPT_MANAGER_ROLE') {
        this.managerList.forEach(v => {
          if (v.staffId == staffId) {
            v.amount = String(v.amount).replace(/,/g, '')
          }
        })
      } else if (key === 'MEMBER_ROLE') {
        this.memberList.forEach(v => {
          if (v.staffId == staffId) {
            v.amount = String(v.amount).replace(/,/g, '')
          }
        })
      }
    },
    blurFn(staffId, key) {
      let num = 0
      this.businessStaffLimitRuleList.forEach(v => {
        if (v.staffId == staffId) {
          num = String(v.amount).replace(/,/g, '')
        }
      })
      if (key === 'VICEGENERAL_MANAGER_ROLE') {
        this.vicegeneralList.forEach(v => {
          if (v.staffId == staffId) {
            let amount = String(v.amount).replace(/,/g, '')
            if (amount != num) {
              v.sign = 1
            } else {
              v.sign = 0
            }
            v.amount = Number(v.amount).toLocaleString()
          }
        })
      } else if (key === 'DEPT_MANAGER_ROLE') {
        this.managerList.forEach(v => {
          if (v.staffId == staffId) {
            let amount = String(v.amount).replace(/,/g, '')
            if (amount != num) {
              v.sign = 1
            } else {
              v.sign = 0
            }
            v.amount = Number(v.amount).toLocaleString()
          }
        })
      } else if (key === 'MEMBER_ROLE') {
        this.memberList.forEach(v => {
          if (v.staffId == staffId) {
            let amount = String(v.amount).replace(/,/g, '')
            if (amount != num) {
              v.sign = 1
            } else {
              v.sign = 0
            }
            v.amount = Number(v.amount).toLocaleString()
          }
        })
      }
    },
    getData() {
      this.loading = true
      this.$api.accunting
        .list({
          deptId: this.activeName,
        })
        .then(async res => {
          this.businessDeptLimitRuleList = []
          this.oldbusinessDeptLimitRuleList = []
          this.businessStaffLimitRuleList = []
          this.vicegeneralList = []
          this.managerList = []
          this.memberList = []
          // this.businessDeptLimitRuleList = res.data?.businessDeptLimitRuleList
          if (res.data && res.data.businessDeptLimitRuleList.length > 0) {
            this.businessDeptLimitRuleList = res.data.businessDeptLimitRuleList
          } else {
            this.businessDeptLimitRuleList = [
              {
                deptId: this.activeName,
                amount: 0,
                roleType: 'VICEGENERAL_MANAGER_ROLE',
              },
              {
                deptId: this.activeName,
                amount: 0,
                roleType: 'DEPT_MANAGER_ROLE',
              },
              {
                deptId: this.activeName,
                amount: 0,
                roleType: 'MEMBER_ROLE',
              },
            ]
          }
          this.businessDeptLimitRuleList.forEach(v => {
            v.sign = 0
            v.amount = Number(v.amount).toLocaleString()
          })
          this.oldbusinessDeptLimitRuleList = await res.data?.businessDeptLimitRuleList.deepClone()
          res.data?.businessStaffLimitRuleList.forEach(v => {
            v.sign = 0
            v.amount = Number(v.amount).toLocaleString()
            if (v.staffName && v.staffName.length === 2) {
              v.staffName = v.staffName.slice(0, 1) + '　' + v.staffName.slice(1)
            }
            if (v.roleType === 'VICEGENERAL_MANAGER_ROLE') {
              this.vicegeneralList.push(v)
            } else if (v.roleType === 'DEPT_MANAGER_ROLE') {
              this.managerList.push(v)
            } else if (v.roleType === 'MEMBER_ROLE') {
              this.memberList.push(v)
            }
          })

          this.businessStaffLimitRuleList = await res.data?.businessStaffLimitRuleList.deepClone()

          this.isShow = false
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getDeptNameList()
        .then(res => {
          this.deptList = res.data
          this.activeName = res.data[0] ? res.data[0].id : '6965573941734416384'
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.deptTop {
  display: flex;
  flex-wrap: wrap;
  .deptClass {
    display: inline-block;
    width: 10%;
    text-align: center;
    color: #000;
    // border: 1px solid #000;
    margin: 8px 0;
  }
  .deptColor {
    color: #409eff;
  }
}
.memberClass {
  display: inline-block;
  margin: 0 10px 10px 0;
  color: #000;
  width: 190px;
}
.ClassName {
  color: #000;
  display: inline-block;
  width: 70px;
  text-align: center;
}
.postName {
  color: #000;
  display: inline-block;
  width: 70px;
  text-align: center;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0;
}
/deep/.el-collapse-item__header {
  font-weight: 900;
  color: #000;
  font-size: 18px;
}
/deep/.labelClassName {
  width: 10px;
}
.bd_input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  // color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 6px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 105px;
}
// 改变input框背景颜色
/deep/.el-input__inner {
  background-color: transparent !important;
  border: 0 !important;
}
/* 利用穿透，设置input边框隐藏 */
.bd_input >>> .el-input__inner {
  border: 0;
}
.color {
  background-color: #f5e9057d;
}
.typefaceColor {
  background-color: #f5e9057d;
}
</style>
